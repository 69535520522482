<template>
  <div class="SalaryQuery_component Others">
    <h3 class="regionNameBorder" v-if="result.scope">{{ result.scope }}</h3>
    <div v-html="result.content" v-if="result.content" style="padding: 0 3px;"></div>
    <!-- 缺省 -->
    <div class="SalaryQueryResult_empty" v-else>
      <van-empty description="暂无数据" />
    </div>
    <!--注释 -->
    <div class="tips" v-if="result.define">
      注释:
      {{ result.define }}
    </div>
  </div>
</template>

<script>
  import { Empty } from "vant";
  export default {
    name: 'Others',
    props: {
      result: Object
    },
    components: {
      [Empty.name]: Empty,
    }
  };
</script>

<style lang="scss" scoped>
  @import url("./style.css");
</style>
